import {rocketApi} from "../api/rocketApi";
import moment from "moment";

let _roomId;
let _lastSeenTimeStamp;
let offset = 0;
const chunkSize = 100;
const messageListeners = new Set();
const sessionMessagesTimeStampsByIds = {};

export const chatSession = {
  _onError: null,
  
  async login(roomId, userToken, onError) {
    const login = await rocketApi.login(userToken);
    _roomId = roomId;
    chatSession._onError = onError;
    rocketApi.setMessageCallback(msgCallback);
    await chatSession.subscribeToChannelMsgs();
    await chatSession.retrieveLastReadTimestampFromServer();
    return login;
  },

  async retrieveLastReadTimestampFromServer() {
    try {
      const {subscription} = (await rocketApi.getSubscription(_roomId));
      _lastSeenTimeStamp = subscription.ls || moment("2020-01-01").toISOString();
    } catch (e) {
      chatSession._onError(`Failed to get subscription and set initial lastSeemTimeStamp for room ${_roomId}`, e);
    }
  },

  getUnreadMessagesCount() {
    if (!_lastSeenTimeStamp) return null;
    return Object.values(sessionMessagesTimeStampsByIds).filter(ts => moment(ts).isAfter(_lastSeenTimeStamp)).length;
  },

  async retrieveMessages() {
    if (!_roomId) return;
    await rocketApi.retrieveMessagesInPrivateRoom(_roomId, chunkSize);
  },

  async loadEarlierMessages() {
    offset += chunkSize;
    if (!_roomId) return;
    await rocketApi.retrieveMessagesInPrivateRoom(_roomId, chunkSize, offset);
  },

  async subscribeToChannelMsgs() {
    await rocketApi.socketConnect();
    rocketApi.subscribeToRoomMessages(_roomId);
  },

  addMessageListener(msgCallback) {
    messageListeners.add(msgCallback);
  },

  async sendMessage(msg) {
    if (!_roomId) return;
    return rocketApi.sendMessage({roomId: _roomId, ...msg});
  },

  getChatAssetSource(uri) {
    return rocketApi.chatAssetSource(_roomId, uri);
  },

  async uploadFileToRoom(photo) {
    try {
      return await rocketApi.uploadFile(_roomId, photo);
    } catch (e) {
      chatSession._onError("Failed to upload file", e);
    }
  },

  async markRoomAsRead() {
    if (!_roomId) return;
    const msgTimestamps = Object.values(sessionMessagesTimeStampsByIds).sort();
    _lastSeenTimeStamp = msgTimestamps[msgTimestamps.length - 1];
    try {
      await rocketApi.markRoomAsRead(_roomId);
    } catch (e) {
      chatSession._onError(`Failed to mark room ${_roomId} as read`, e);
    }
    msgCallback([]);
  }
};

async function msgCallback(msgs) {
  const nonSystemMessages = filterOutSystemMsgs(Array.isArray(msgs) && msgs ||msgs.messages);

  nonSystemMessages.forEach(msg => {
    sessionMessagesTimeStampsByIds[msg._id] = msg.ts instanceof Object ? moment(msg.ts["$date"]) : msg.ts;
  });

  for (let listener of messageListeners.values()) {
    listener(nonSystemMessages);
  }
}

function filterOutSystemMsgs(messages) {
  return messages.filter(m => !m.t)
}
